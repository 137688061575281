
@font-face
{
	font-family: 'JosefinSans-Light';
	src: local(JosefinSans-Light);
	src: url(./fonts/JosefinSans-Light.eot);
	src: url(./fonts/JosefinSans-Light.eot?#iefix) format('embedded-opentype'),
		url(./fonts/JosefinSans-Light.otf) format('opentype'),
		url(./fonts/JosefinSans-Light.ttf) format('truetype'),
		url(./fonts/JosefinSans-Light.woff) format('woff'),
		url(./fonts/JosefinSans-Light.woff2) format('woff2'),
		url(./fonts/JosefinSans-Light.svg#JosefinSans-Light) format('svg');
}

@font-face
{
	font-family: 'Nauman-Regular';
	src: local(Nauman-Regular);
	src: url(./fonts/Nauman-Regular.eot);
	src: url(./fonts/Nauman-Regular.eot?#iefix) format('embedded-opentype'),
		url(./fonts/Nauman-Regular.otf) format('opentype'),
		url(./fonts/Nauman-Regular.ttf) format('truetype'),
		url(./fonts/Nauman-Regular.woff) format('woff'),
		url(./fonts/Nauman-Regular.woff2) format('woff2'),
		url(./fonts/Nauman-Regular.svg) format('svg');
}
