@import 'fonts';
@import 'utils';



/*
#warning-message
{
	display: none;
	color: #FFFFFF;
	font-size:7vmin;
	text-align: center;
	margin-top:30vh;
	margin-left:10%;
	margin-right:10%;
	width: 80%;
	> img
	{
		width: 40%;
	}
}
@media only screen and (orientation:portrait) and (max-width:500px)
{
	#container			{ display: none; }
	#warning-message	{ display: block; }
}
@media only screen and (orientation:landscape)
{
	#warning-message	{ display: none; }
}
*/

html, body
{
	margin: 0;
	padding: 0;
	overflow: hidden;
	height: 100%;
}

.App
{
	overflow: hidden;
	font-style: normal;
	font-weight: lighter;
	font-family: JosefinSans-Light,Nauman-Regular,Verdana,Tahoma,monospace;
	min-height: 100%;
	display: block;
}

.Header
{
	overflow: hidden;
	position: absolute;
	width: 100%;
	height: 15%;
	text-align: center;
	color: white;
}

h1
{
	font-size:5vmin;
	text-align: center;
	margin-top:8vh;
}

h2
{
	color: #FFFFFF;
	font-size:5vmin;
	text-align: center;
	margin-top:30vh;
}

h3
{
	position: absolute;
	width: 100%;
	color: #FFFFFF;
	font-size:5vmin;
	text-align: center;
	margin-top:40vh;
}

h4
{
	position: absolute;
	width: 100%;
	color: #FFFFFF;
	font-size:3vmin;
	text-align: center;
	margin-top:10vh;
}

.text-gradient
{
	opacity: 1 !important;
	z-index: 1000;
	color: hsla(0,0%,100%,.9);
	background: -webkit-linear-gradient(to left,hsla(0,0%,100%,.3),#fff,hsla(0,0%,100%,.3));
	background: 		linear-gradient(to left,hsla(0,0%,100%,.3),#fff,hsla(0,0%,100%,.3));
	-webkit-background-clip: text;
			background-clip: text;
	-webkit-text-fill-color: transparent;
			text-fill-color: transparent;
}

.BackgroundVideo
{
	overflow: hidden;
	position: fixed;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	z-index: -100;
	transform: translateX(-50%) translateY(-50%);
}
.BackgroundVideo > video
{
	object-fit: fill;
	width: 100%;
	height: 100%;
}

.MirrorHallContainer
{
	position: absolute;
	top: 15%;
	left: 0;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 85%;
	text-align:center;
	touch-action: none;
	-webkit-tap-highlight-color: transparent;
	@include backface-visibility(hidden);
	@include perspective(100vw);
	@include user-select(none);
}

.NavButtons
{
	position: absolute;
	width: 100%;
	height: 50%;
}

.ToggleButton
{
	width: 100%;
	top:40vh;
	cursor:pointer;
}
.ToggleButton.left	> img { left: 15vw; }
.ToggleButton.right	> img { right:15vw; @include transform(scaleX(-1)); }
.ToggleButton > img
{
	position:fixed;
	top: 40vh;
	width: 4vw;
	height: 4vw;
	@include transition(width .5s, height .5s);
}
.ToggleButton:hover > img
{
	width: 5vw;
	height: 5vw;
}

$card-w: 20vw;
$card-h: 60vh;
$card-rotate: 45deg;

.MirrorHall
{
	position: absolute;
	left:	50%;
	top:	50%;
	margin-top:  -($card-h / 2);
	height:	$card-h;
	width:	$card-w;
	min-width: 100px;
	max-width: $card-h * 0.714285;
	@include transform(translateX(-50%));
	@include transform-style(preserve-3d);
}

.MirrorCard
{
	position: absolute;
	display: table;
	width: $card-w;
	height: $card-h;
	min-width: 100px;
	max-width: $card-h * 0.714285;
	min-height: 50vh;
	max-height: $card-w / 0.714285;
	text-align: center;
	cursor: pointer;
	opacity: 1;
	border: none;
	background-position: center;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	-moz-osx-font-smoothing: grayscale;
	@include transition(transform 1s, background-size .5s);
	> .Border
	{
		position: absolute;
		top:	2%;
		bottom:	2%;
		left:	3%;
		right:	3%;
		border: 3px solid #222;
		opacity: 1;
		@include transition(border-color .5s, opacity .5s);
	}
}

.MirrorCard.left		{	left:-120%;	}
.MirrorCard.left.front	{	left:-100%;	}
.MirrorCard.turn-l
{
	@include transition-property(all);
	@include transform(translateZ(-2vw) rotateY(-$card-rotate) scale(.95));
}

.MirrorCard.right		{	left:120%;	}
.MirrorCard.right.front	{	left:100%;	}
.MirrorCard.turn-r
{
	@include transition-property(all);
	@include transform(translateZ(-2vw) rotateY($card-rotate) scale(.95));
}

.MirrorCard.center			{	left: 0%;	}
.MirrorCard.center.turn-l	{	left:-100%;	}
.MirrorCard.center.turn-r	{	left: 100%;	}
.MirrorCard.front
{
	@include transition-property(all);
	@include transform(translateZ(10vw) rotateY(0));
}

.MirrorCardHighlight
{
	position: absolute;
	background-position: 50% 50%;
	background-size: 100% 100%;
	opacity:0;
	width: 100%;
	height: 100%;
	@include transition(opacity .8s, background-size 1s);
}

.MirrorCard:hover
{
	background-size: 120% 120%;
}

.MirrorCard.center:hover,
.MirrorCard.center.hover
{
	> .Border
	{
		opacity: 0;
	}
	.MirrorCardHighlight
	{
		background-size: 120% 120%;
	}
}



@media screen and (max-width:800px)
{
	.MirrorHall
	{
		width: 30vw;
	}
	.MirrorCard
	{
		width: 30vw;
		min-height: 45vh;
	}
}

@media screen and (max-width:500px)
{
	.MirrorHall
	{
		width: 40vw;
	}
	.MirrorCard
	{
		width: 40vw;
		min-height: $card-w / 0.714285;
		max-height: $card-w / 0.714285;
	}
	.MirrorCard.left	{ left:-105%; }
	.MirrorCard.right	{ left: 105%; }
}
